import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Button,
  Box,
  useBreakpointValue,
  IconButton,
  Icon,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Link,
  AccordionIcon,
} from '@chakra-ui/react';
import { AppButton, AppText } from '@backlinkit/shared';
import { AppColors } from '../../theme';
import { NavbarLink } from '../../models/nav-bar';
import { useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useClientPanel } from '../../providers/panel/web-panel-service';
import { IoMdArrowDropdown } from 'react-icons/io';

export interface NavBarProps {
  logo: string;
  links: NavbarLink[];
  signUp: () => void;
}

export const NavBarBeta: React.FC<NavBarProps> = ({ logo, links, signUp }) => {
  const navigate = useNavigate();
  const panel = useClientPanel();
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });

  const handleMenu = () => {
    panel({
      size: 'full',
      render: (onSubmit) => {
        return (
          <Flex bgColor={AppColors.primary} flexDir={'column'} gap={4}>
            <Accordion allowToggle>
              {links.map((item, idx) => (
                <Flex flexDir={'column'}>
                  {item.linkedItems && (
                    <AccordionItem key={`${item.title}-${idx}`} border={'none'} mt={2}>
                      <AccordionButton
                        display={'flex'}
                        width={'100%'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <AppText color={'white'} fontSize={'18px'}>
                          {item.title}
                        </AppText>
                        <AccordionIcon color={'white'} />
                      </AccordionButton>
                      <AccordionPanel>
                        <Flex flexDir={'column'} gap={2}>
                          {item.linkedItems?.map((link, idx) => (
                            <Link
                              key={`${link.title}-${idx}`}
                              onClick={() => navigate(link.href)}
                              color={'white'}
                            >
                              {link.title}
                            </Link>
                          ))}
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                  {item.href && (
                    <Link
                      key={`${item.title}-${idx}`}
                      onClick={() => navigate(item.href ?? '')}
                      color={'white'}
                      _hover={{ textDecoration: 'none' }}
                      textDecor={'none'}
                      fontSize={'18px'}
                      pl={4}
                      mt={4}
                    >
                      {item.title}
                    </Link>
                  )}
                </Flex>
              ))}
            </Accordion>
          </Flex>
        );
      },
    });
  };

  const renderMenu = (item: NavbarLink) => {
    return (
      <>
        {item.linkedItems && (
          <Menu>
            <MenuButton
              as={Button}
              bgColor={'transparent'}
              color={'white'}
              fontWeight={'700'}
              _hover={{
                bgColor: 'transparent',
              }}
              px={4}
              display={'flex'}
              alignItems={'center'}
              width={'100%'}
            >
              <Flex align={'center'} gap={2}>
                <AppText>{item.title}</AppText>
                <IoMdArrowDropdown color="white" />
              </Flex>
            </MenuButton>
            <MenuList borderRadius={'xl'}>
              {item.linkedItems?.map((link, idx) => (
                <MenuItem key={`${link.title}-${idx}`} onClick={() => navigate(link.href)}>
                  <AppText fontSize={'16px'}>{link.title}</AppText>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
        {item.href && (
          <Link color={'white'} onClick={() => navigate(item.href ?? '')} px={4}>
            {item.title}
          </Link>
        )}
      </>
    );
  };

  return (
    <Flex
      minH={'50px'}
      p={variant === 'mobile' ? 1 : 4}
      width={'100%'}
      justify={variant === 'mobile' ? 'space-between' : 'space-evenly'}
      align={'center'}
      bgColor={AppColors.primary}
    >
      {variant === 'desktop' && (
        <>
          <Flex>
            <Image height={'35px'} w={'auto'} src={logo} onClick={() => navigate('/')} />
          </Flex>
          <Flex align={'center'} gap={'20px'}>
            {links.map((item, idx) => (
              <Box key={`${item.title}-${idx}`}>{renderMenu(item)}</Box>
            ))}
          </Flex>
        </>
      )}
      {variant === 'tablet' && (
        <Flex align={'center'} width={'100%'}>
          <IconButton
            _hover={{
              bgColor: 'transparent',
            }}
            bgColor={'transparent'}
            aria-label="mobile-menu"
            onClick={handleMenu}
          >
            <Icon as={HamburgerIcon} color={'white'} />
          </IconButton>
          <Image src={logo} maxH={'28px'} width={'auto'} onClick={() => navigate('/')} />
        </Flex>
      )}
      {variant === 'mobile' && (
        <Flex align={'center'} width={'100%'}>
          <IconButton
            _hover={{
              bgColor: 'transparent',
            }}
            bgColor={'transparent'}
            aria-label="mobile-menu"
            onClick={handleMenu}
          >
            <Icon as={HamburgerIcon} color={'white'} />
          </IconButton>
          <Image src={logo} maxH={'20px'} width={'auto'} onClick={() => navigate('/')} />
        </Flex>
      )}
      <Flex align={'center'} gap={4}>
        <AppButton
          bgColor={AppColors.ctaColor}
          borderColor={'none'}
          onClick={signUp}
          borderRadius={'full'}
          color={'white'}
          size={variant === 'mobile' ? 'xs' : 'md'}
          p={variant === 'mobile' ? 4 : 'auto'}
        >
          Notify Me
        </AppButton>
      </Flex>
    </Flex>
  );
};
