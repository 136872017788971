import { Flex, useBreakpointValue, Image, useToast, Link } from '@chakra-ui/react';
import background from '../../assets/home-background.png';
import { AppText, WaitListData } from '@backlinkit/shared';
import { WaitListForm } from '../../components/wait-list-form/wait-list-form';
import whiteLogo from '../../assets/backlinkit-white-logo.png';
import { useCreateUserLeadMutation } from '../../store/api/userLeadApi';
import { signUpBanner } from '../../constants/waitlist-company';
import { AppColors } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';

export const HomeV2: React.FC = () => {
  const navigate = useNavigate();

  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });

  const linkFontSize = variant === 'mobile' ? '14px' : '16px';

  const [createTrigger] = useCreateUserLeadMutation();
  const toast = useToast();

  const upsertUserLead = async (formData: WaitListData) => {
    try {
      await createTrigger({
        email: formData.email,
        displayName: formData.displayName,
      }).unwrap();
      toast({
        title: "You're signed up",
        description: "We'll notify you when we go live",
        status: 'success',
        isClosable: true,
        duration: 9000,
      });
    } catch (error) {
      toast({
        title: 'Email exists',
        description:
          'Thanks for really wanting to use the system! We already have this email signed up.',
        status: 'warning',
        isClosable: true,
        duration: 9000,
      });
    }
  };
  return (
    <Flex
      flexDir={'column'}
      align={'center'}
      minW={'100vw'}
      bgImage={background}
      bgSize={'cover'}
      bgRepeat={'no-repeat'}
      bgPos={'center'}
      minH={'100vh'}
      overflow={'auto'}
    >
      <Flex height={'120px'} w={'100%'} justify={'center'}>
        <Flex
          maxW={'1140px'}
          w={'100%'}
          align={'center'}
          gap={4}
          px={variant === 'desktop' ? 0 : 7}
        >
          <Image src={whiteLogo} maxH={'30px'} w={'auto'} />
          <AppText color={'white'} fontWeight={'600'} fontSize={'12px'}>
            | beta testing closed
          </AppText>
        </Flex>
      </Flex>
      <Flex
        w={'100%'}
        justify={'center'}
        maxW={'1140px'}
        minH={'400px'}
        align={'center'}
        flexDir={variant === 'desktop' ? 'row' : 'column'}
        px={variant === 'desktop' ? 0 : 7}
        gap={variant === 'desktop' ? 0 : '80px'}
      >
        <Flex flexDir={'column'} gap={4} w={'100%'}>
          <AppText color={'white'} variant={'h1'} fontWeight={'700'} className="poppins">
            Change Your SEO Trajectory
          </AppText>
          <AppText color={'white'} className="poppins">
            Did you know: Almost 60% of authoritative backlinks are deleted, moved or changed within
            the first 45 days of purchase? We know all too well how much broken links can cost your
            online business.
          </AppText>
          <AppText color={'white'} className="poppins">
            The solution: Our intelligent tool tracks your backlinks 24/7 and helps recover your
            broken links.
          </AppText>
          <AppText color={'white'} className="poppins">
            The result: Boosted search rankings and an increase in quality traffic to your website.
            It’s next-level backlink intelligence to safeguard your domain authority.
          </AppText>
        </Flex>
        <Flex w={'100%'} justify={'center'}>
          <WaitListForm
            onSubmit={async (formData) => {
              await upsertUserLead(formData);
            }}
          />
        </Flex>
      </Flex>
      <Flex
        py={'60px'}
        px={variant !== 'desktop' ? 3 : '135px'}
        align={'center'}
        justify={'center'}
        flexDir={'column'}
        gap={4}
        width={'100vw'}
      >
        <Flex
          align={'center'}
          justify={'space-evenly'}
          width={'100%'}
          maxW={'1140px'}
          overflowX={'auto'}
        >
          {signUpBanner.map((item, idx) => (
            <Image src={item} key={`${item}-${idx}`} maxH={'auto'} width={'180px'} />
          ))}
        </Flex>
      </Flex>

      <Flex
        width={'100%'}
        p={2}
        justify={'center'}
        bgColor={AppColors.iconColor}
        pos={'absolute'}
        bottom={0}
        marginTop={4}
        left={0}
      >
        <Flex
          align={'center'}
          flexDir={variant === 'mobile' ? 'column-reverse' : 'row'}
          gap={3}
          justify={'space-between'}
          width={'1140px'}
        >
          <AppText color={'white'} fontSize={'16px'}>
            © 2024 Backlinkit.com. All Rights Reserved
          </AppText>
          <Flex gap={variant !== 'mobile' ? 8 : 3} align={'center'}>
            <AppText
              cursor={'pointer'}
              onClick={() =>
                window.open(
                  'https://backlinkitprod.blob.core.windows.net/policies/Cookie%20Policy%20BacklinkIt%20FINAL.pdf',
                  '_blank'
                )
              }
              color={'white'}
              fontSize={linkFontSize}
            >
              Cookie Policy
            </AppText>
            <AppText
              cursor={'pointer'}
              onClick={() =>
                window.open(
                  'https://backlinkitprod.blob.core.windows.net/policies/Website%20Privacy%20Policy%20BacklinkIt%20FINAL.pdf',
                  '_blank'
                )
              }
              color={'white'}
              fontSize={linkFontSize}
            >
              Privacy Policy
            </AppText>
            <AppText
              cursor={'pointer'}
              onClick={() => navigate(RoutesList.Legal)}
              color={'white'}
              fontSize={linkFontSize}
            >
              Legals
            </AppText>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
