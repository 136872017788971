import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';

const AppColors = {
  primary: '#047EFB',
  secondary: '#2CD7FB',
  tertiary: '#606060',
  contentColor: '#b1b1b1',
  featuredColor: '#0367FC',
  innerContentColor: '#ffffff',
  appBackground: '#eeeeee',
  secondaryBackground: '#rgba(45, 188, 250, 0.1)',
  appBorder: '#F1F4F7',
  ctaColor: '#FF8800',
  footerBackground: '#3B4147',
  iconColor: '#32302E',
};

const textStyles = {
  h1: {
    fontSize: ['36px', '56px'],
    fontWeight: '700',
  },
  h2: {
    fontSize: ['36px', '48px'],
    fontWeight: '700',
  },
  h3: {
    fontSize: ['22px', '36px'],
    fontWeight: '700',
  },
  h4: {
    fontSize: ['20px', '24px'],
    fontWeight: '700',
  },
  p1: {
    fontSize: '18px',
  },
  body: {
    fontSize: ['12px', '14px', '16px'],
    fontWeight: '400',
  },
};

const buttonComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'medium',
    color: AppColors.primary,
    rounded: 'md',
    border: 'none',
    cursor: 'pointer',
  },
  sizes: {
    xs: {
      fontSize: '12px',
      px: 2,
      py: 1,
    },
    sm: {
      fontSize: '16px',
    },
    md: {
      fontSize: '18px',
      px: 8,
      py: 4,
    },
    lg: {
      fontSize: '20px',
    },
    xl: {
      fontSize: '26px',
      px: 8,
      py: 4,
    },
    xxl: {
      fontSize: '36px',
      px: 8,
      py: 4,
    },
  },
  variants: {
    ghost: {
      bgColor: 'transparent !important',
      color: AppColors.innerContentColor,
      border: `1px solid ${AppColors.innerContentColor}`,
      borderRadius: 'full !important',
      cursor: 'pointer',
    },
    ctaVariant: {
      bgColor: AppColors.ctaColor,
      color: 'white',
      border: 'none',
      borderRadius: 'full !important',
      cursor: 'pointer',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};

const theme = extendTheme({
  textStyles: textStyles,
  components: {
    Button: buttonComponentStyle,
  },
});

type AppTextStyles = keyof typeof textStyles;
type AppButtonVariants =
  | 'underline'
  | 'solid'
  | 'outline'
  | 'ghost'
  | 'withStartIcon'
  | 'roundedUnderline'
  | 'ctaVariant';
type AppColorsType = keyof typeof AppColors;

export { theme, AppColors };
export type { AppTextStyles, AppButtonVariants, AppColorsType };
