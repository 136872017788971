import { BaseLayout } from '../../layouts/base-layout';
import { AppFaqSection } from '../../components/app-faq-section/app-faq-section';
import { AppComponentWrapper } from '../../components/app-component-wrapper/app-component-wrapper';
import { AppBasicHero } from '../../components/app-basic-hero/app-basic-hero';
import { useBreakpointValue, SimpleGrid } from '@chakra-ui/react';
import heroBackground from '../../assets/simple-hero-background.png';
import { faqs } from '../../constants/faqs';
import LegalCard from '../../components/app-legal-card/app-legal-card';
import { legalItems } from '../../constants/legal';
import { useMediaQuery } from '@chakra-ui/react';

export const LegalPage: React.FC = () => {
  const [isSmallScreen] = useMediaQuery('(max-width: 375px)');
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'medium',
    xl: 'desktop',
  });

  const breakpointColumns = {
    base: 2,
    md: 2,
    lg: 2,
    xl: 3,
  };

  const columns = isSmallScreen ? 1 : breakpointColumns;

  return (
    <BaseLayout>
      <AppComponentWrapper>
        <AppBasicHero pageName="Legal" title="Legal Information" backgroundImage={heroBackground} />
      </AppComponentWrapper>

      <AppComponentWrapper
        px={variant === 'desktop' ? '135px' : '18px'}
        py={variant === 'desktop' ? '120px' : '68px'}
        flexWrap="wrap"
      >
        <SimpleGrid columns={columns} gap={variant === 'desktop' ? '30px' : '14px'}>
          {legalItems.map((item, idx) => (
            <LegalCard key={idx} icon={item.icon} title={item.title} link={item.link} />
          ))}
        </SimpleGrid>
      </AppComponentWrapper>

      {/* <AppComponentWrapper>
        <AppFaqSection
          title="Frequently asked questions"
          description="We have put together some commonly asked questions"
          faqs={faqs}
          variant="light"
        />
      </AppComponentWrapper> */}
    </BaseLayout>
  );
};
