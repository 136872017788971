import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PricingPage } from '../pages/pricing-v2/pricing-page';
import ToolsPage from '../pages/tools/tools';
import { HomePage } from '../pages/home/home';
import { ChangeLog } from '../pages/change-log/change-log';
import { ManagedServices } from '../pages/managed-services/managed-services';
import { AboutUs } from '../pages/about-us/about-us';
import { HomeV2 } from '../pages/home-v2/home-page';
import { LegalPage } from '../pages/legal/legal';
import { PrivacyPolicy } from '../pages/privacy-policy/privacy-policy';

export enum RoutesList {
  Index = '/',
  Home = '/home',
  Pricing = '/pricing',
  ChangeLog = '/change-log',
  ManagedServices = '/managed-services',
  AboutUs = '/about-us',
  Tools = '/tools',
  Legal = '/legal',
  PrivacyPolicy = '/policies/privacy',
}

const Router: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {/* <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomeV2 />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/change-log" element={<ChangeLog />} />
        <Route path="/managed-services" element={<ManagedServices />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/tools" element={<ToolsPage />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route path="/policies/privacy" element={<PrivacyPolicy />} /> */}

        <Route path="/" element={<HomeV2 />} />
        <Route path="/legal" element={<LegalPage />} />
        {/* <Route path="/policies/privacy" element={<PrivacyPolicy />} /> */}
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
