import * as React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { NavBar } from './header/header';
import { AppFooter } from './v3Footer/app-footer';
import headerLogo from '../assets/backlinkit-white-logo.png';
import { betaNavItems, navItems } from '../constants/nav-items';
import { NavBarBeta } from './header/headerBeta';
import { useNavigate } from 'react-router-dom';

export interface BaseLayoutProps extends FlexProps {}

export const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <Flex flexDir={'column'} minH={'100vh'} overflow={'auto'}>
      {/* <NavBar logo={headerLogo} links={navItems} login={() => {}} signUp={() => {}} /> */}
      <NavBarBeta logo={headerLogo} links={[]} signUp={() => navigate('/')} />

      <Flex w={'100%'} justify={'center'} flexDir={'column'} align={'center'} pos={'relative'}>
        {children}
      </Flex>
      {/* <AppFooter /> */}
    </Flex>
  );
};
