import { ReactComponent as PrivacyPolicyIcon } from '../assets/icons/legal/privacy.svg';
import { ReactComponent as TermsOfServiceIcon } from '../assets/icons/legal/terms-of-service.svg';
import { ReactComponent as ContentPolicyIcon } from '../assets/icons/legal/content.svg';
import { ReactComponent as CookiePolicyIcon } from '../assets/icons/legal/cookie.svg';
import { ReactComponent as StandardContractualIcon } from '../assets/icons/legal/standard.svg';
import { ReactComponent as DataProcessingWayIcon } from '../assets/icons/legal/data-processing.svg';
import { ReactComponent as ProcessorsIcon } from '../assets/icons/legal/data-processing.svg';
import { ReactComponent as RecruitmentPolicyIcon } from '../assets/icons/legal/recruitment.svg';
import { ReactComponent as AffiliateProgramIcon } from '../assets/icons/legal/affiliate-program.svg';

import { LegalCardProps } from '../models/legal';

// export const legalItems: LegalCardProps[] = [
//   {
//     title: 'Privacy Policy',
//     link: 'https://backlinkitprod.blob.core.windows.net/policies/Website%20Privacy%20Policy%20BacklinkIt%20FINAL.pdf',
//     icon: PrivacyPolicyIcon,
//   },
//   {
//     title: 'Terms of Service',
//     link: 'https://backlinkitprod.blob.core.windows.net/policies/BacklinkIt%20General%20Terms%20of%20Use%20FINAL.pdf',
//     icon: TermsOfServiceIcon,
//   },
//   {
//     title: 'Content Policy',
//     link: '',
//     icon: ContentPolicyIcon,
//   },
//   {
//     title: 'Cookie Policy',
//     link: 'https://backlinkitprod.blob.core.windows.net/policies/Cookie%20Policy%20BacklinkIt%20FINAL.pdf',
//     icon: CookiePolicyIcon,
//   },
//   {
//     title: 'Standard Contractual',
//     link: '',
//     icon: StandardContractualIcon,
//   },
//   {
//     title: 'Data Processing Way',
//     link: '',
//     icon: DataProcessingWayIcon,
//   },
//   {
//     title: 'Processors',
//     link: '',
//     icon: ProcessorsIcon,
//   },
//   {
//     title: 'Recruitment Policy',
//     link: '',
//     icon: RecruitmentPolicyIcon,
//   },
//   {
//     title: 'Affiliate Program',
//     link: '',
//     icon: AffiliateProgramIcon,
//   },
// ];

export const legalItems: LegalCardProps[] = [
  {
    title: 'Privacy Policy',
    link: 'https://backlinkitprod.blob.core.windows.net/policies/Website%20Privacy%20Policy%20BacklinkIt%20FINAL.pdf',
    icon: PrivacyPolicyIcon,
  },
  {
    title: 'Terms of Service',
    link: 'https://backlinkitprod.blob.core.windows.net/policies/BacklinkIt%20General%20Terms%20of%20Use%20FINAL.pdf',
    icon: TermsOfServiceIcon,
  },
  {
    title: 'Cookie Policy',
    link: 'https://backlinkitprod.blob.core.windows.net/policies/Cookie%20Policy%20BacklinkIt%20FINAL.pdf',
    icon: CookiePolicyIcon,
  },
  {
    title: 'Disclaimer',
    link: 'https://backlinkitprod.blob.core.windows.net/policies/Disclaimer%20BacklinkIt%20FINAL.pdf',
    icon: ContentPolicyIcon,
  },
  {
    title: 'Definitions',
    link: 'https://backlinkitprod.blob.core.windows.net/policies/Definitions%20Key%20BacklinkIt%20FINAL.pdf',
    icon: StandardContractualIcon,
  },
];
