import { Stack } from '@chakra-ui/react';
import { AppInput, AppButton, AppText, AppColors, CreateWaitList } from '@backlinkit/shared';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { BaseFormProps } from '../../models/base';
import { yupResolver } from '@hookform/resolvers/yup';

const waitListFormDefaults: CreateWaitList = {
  email: '',
  displayName: '',
};

const waitListDataSchema = yup.object({
  email: yup.string().required('Field is required'),
  displayName: yup.string().required('Field is required '),
});

type WaitListFormProps<T> = {} & BaseFormProps<T>;

export const WaitListForm: React.FC<WaitListFormProps<CreateWaitList>> = ({ onSubmit }) => {
  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<CreateWaitList>({
    defaultValues: waitListFormDefaults,
    resolver: yupResolver(waitListDataSchema),
    mode: 'onChange',
  });

  return (
    <Stack spacing={4} maxW={'350px'} w={'100%'}>
      <AppInput<CreateWaitList>
        name={'email'}
        control={control}
        error={undefined}
        label="Email"
        labelColor='white'
      />
      <AppInput<CreateWaitList>
        name={'displayName'}
        control={control}
        error={undefined}
        label="Name"
        rounded={'full'}
        labelColor='white'
      />
      <AppButton
        bgColor={AppColors.ctaColor}
        mt={4}
        color={'white'}
        borderRadius={'full'}
        height={'60px'}
        onClick={handleSubmit(onSubmit)}
        isDisabled={!isValid}
        borderColor={'none !important'}
        
      >
        <AppText fontSize={'18px'}>Notify me when you go live</AppText>
      </AppButton>
      <AppText color={'white'} fontSize={'12px'} fontWeight={'600'}>Get Your 7-Day Free Trial With 200 Credits On Us!</AppText>
    </Stack>
  );
};
