import { AppColors, AppText, AppButton } from '@backlinkit/shared';
import { Flex, FlexProps, Image, useBreakpointValue } from '@chakra-ui/react';

export interface AppBasicHeroProps extends FlexProps {
  pageName: string;
  backgroundImage?: string;
  title?: string;
  image?: string;
}

export const AppBasicHero: React.FC<AppBasicHeroProps> = ({
  pageName,
  backgroundImage,
  image,
  title,
  ...props
}) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });

  const contentJustify = () => {
    if (variant === 'desktop' && !image) {
      return 'center';
    }
    if (variant === 'desktop' && image) {
      return 'start';
    }
    return 'center';
  };

  return (
    <Flex
      backgroundImage={backgroundImage}
      backgroundPosition={'center'}
      backgroundSize={'cover'}
      bgColor={AppColors.secondary}
      w={'100%'}
      justify={'center'}
      py={image ? '40px' : 0}
    >
      <Flex
        minH={'370px'}
        justify={image ? 'space-between' : 'center'}
        align={'center'}
        width={'100%'}
        maxW={'1140px'}
        gap={variant === 'desktop' ? 0 : 7}
        flexDir={variant === 'desktop' ? 'row' : 'column'}
        px={variant === 'desktop' ? 0 : 7}
      >
        <Flex flexDir={'column'} justify={'center'} align={contentJustify()} gap={6}>
          <Flex align={'center'} gap={2}>
            <AppText fontSize={'18px'} color={'white'}>{`Home /`}</AppText>
            <AppText fontSize={'18px'} color={'white'} fontWeight={'700'}>{`${pageName}`}</AppText>
          </Flex>
          <AppText variant={'h2'} fontWeight={'700'} color={'white'}>
            {title ?? pageName}
          </AppText>
        </Flex>
        {image && <Image src={image} width={'auto'} maxH={'400px'} />}
      </Flex>
    </Flex>
  );
};
